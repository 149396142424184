import React, { useEffect, useState } from 'react'
import { navigate } from 'gatsby'
import { getMethod } from '../../helper/api';
import { Modal } from "react-bootstrap"

const Teacher = props => {

  const [rating, setRating] = useState(0);
  const [modalIsOpen, setIsOpen] = useState(false)
  const [closeModal, setCloseModal] = useState(false)
  function showTeachers(){
    navigate('/booking-teacher?v='+window.localStorage.getItem('appVersion'),{state:{subject:props.subject, level: props.level, tutors: props.tutors}});
  }

  function selectTeacher(id){
    window.localStorage.setItem('selectedTeacher',id);
    navigate(-1)
  }

  if(!props.teacher){
    props.teacher = {};
  }

  useEffect(()=>{
    async function getrating(){
      let rating = await getMethod('/tutor/rating',"",{id:props.teacher.id});
      //console.log(rating);
      if(rating.data[0][0]!==undefined)
        setRating(rating.data[0][0].rating);
    }

    getrating();
  },[])

  return (
    <div className='teacher-card text-center card mb-0' >
          <div className="avatar mx-auto mb-2" onClick={()=>{if(props.select) selectTeacher(props.teacher.id)}}>
            {!props.teacher.photo && <i className="icon-Profile icon" style={{ margin: 'auto', fontSize: 30 }} />}
            {props.teacher.photo && <img src={props.teacher.photo} width="50" height="50" />}
          </div>
          <h6><strong>{props.teacher.firstname} {props.teacher.lastname}</strong></h6>
          <p className="desc mb-2">{props.teacher.position} Tutor</p>
          <div className="d-flex justify-content-center">
          <div style={{display:'block'}}>
                  <div class="ratings1">
                      <div class="empty-stars"></div>
                      <div class="full-stars" t={rating} style={{width:`${(rating/5)*100}%`}}></div>
                  </div>
                  </div>
            </div>
            <div>
            <i className='icon icon-Info' onClick={(e)=>{e.preventDefault();setIsOpen(!modalIsOpen)}}></i>
            
          </div> 
          <div style={{ position: 'fixed', bottom: 0, width: '100%', }}>
          {rating>=0 && <Modal show={modalIsOpen} contentLabel="Example Modal">
            <Modal.Body  style={ {minHeight:window?window.innerHeight-150+'px':'600px'}}>
              <div className="row align-items-center">
                <div className="col"><h6 className="mb-0" style={{fontSize:'18px'}}><strong>Info</strong></h6></div>
                <div className="col-auto ms-auto"><button class="btn mb-0" onClick={(e)=>{e.preventDefault();setIsOpen(false)}} style={{
                    border: '1px solid #0c71c3',
                    borderCollapse: 'collapse',
                    background: '#0c71c3',
                    color: '#fff',
                    width: '25px',
                    padding: '0 0px',
                    height: '25px',
                    margin: 'auto',
                    lineHeight: '0px',
                    fontSize: '11px'
                }}>X</button></div>
              </div>
              <div className="booking-details" style={{fontSize:'14px'}}>
                <div>
                  <div style={{width:'35%',float:'left', padding:'16px 0px'}}>Name</div>
                  <div className="card card-info">
                    <div> <b>{props.teacher.firstname} {props.teacher.lastname}</b></div>
                  </div>
                </div>
                <div>
                  <div style={{width:'35%',float:'left', padding:'16px 0px'}}>Known Languages</div>
                  <div className="card card-info">
                    <div> <b>{props.teacher.known_languages}</b></div>
                  </div>
                </div>
                <div>
                  <div style={{width:'35%',float:'left', padding:'16px 0px', clear:'left'}}>Experience</div>
                  <div className="card card-info" style={{minWidth:'60%'}}>
                    <div> <b>{props.teacher.experience}</b></div>
                  </div>
                </div>
                <div>
                  <div style={{width:'35%',float:'left', padding:'16px 0px'}}>Rating</div>
                  <div className="card card-info teacher-card">
                    {/* <div> {[...Array(Math.ceil(rating))].map((e,i)=>
                                <i className="icon icon-Star_Fill" key={i}></i>
                            )}
          {[...Array(5-Math.ceil(rating))].map((e,i)=>
                                <i className="icon icon-Star" key={i}></i>
                            )}</div> */}
                  
                  <div style={{display:'block'}}>
                  <div class="ratings1">
                      <div class="empty-stars"></div>
                      <div class="full-stars" t={rating} style={{width:`${(rating/5)*100}%`}}></div>
                  </div>
                  </div>
                  </div>
                </div>
                <div>
                  <div style={{width:'35%',float:'left', padding:'16px 0px'}}>Summay</div>
                  <div className="card card-info">
                    <div style={{whiteSpace:'pre-line'}}> {props.teacher.detail_summary}</div>
                  </div>
                </div>
              </div>
            </Modal.Body>
          </Modal> }
          </div>
        {/*{!props.select && (<i
            className='icon icon-Right ml-auto a-self-center'
            style={{ fontSize: 40, cursor: 'pointer' }}
            onClick={()=>showTeachers()}
        />)}*/}
    </div>
  )
}

export default Teacher
