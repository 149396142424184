import React, { useEffect, useState } from "react"
import { connect, useState as reduxState, useSelector } from "react-redux"
import Layout from "../components/layout"
import Seo from "../components/seo"
import "semantic-ui-css/components/dropdown.min.css"
import Tutor from "../components/teacher/teacher-component-small"
import SlotComponent from "../components/teacher/slots"
import { getMethod, postMethod } from "../helper/api"
import "react-calendar/dist/Calendar.css"
import Select from "react-select"
import { navigate } from "gatsby"
import { getWindowItem } from "../helper/globals"
import moment from "moment"
import { Modal } from "react-bootstrap"
import { notify } from "react-notify-toast"
import { objectOf } from "prop-types"
const customStyles = {
  menu: (provided, state) => ({
    ...provided,

    borderBottom: "1px dotted pink",
    color: state.selectProps.menuColor,
  }),

  control: (provided, state) => ({
    ...provided,
    height: "28px",
    //padding: '2px',
    border: "1px solid #ccc",
    borderRadius: "18px",
  }),

  valueContainer: (provided, state) => ({
    ...provided,
    height: "28px",
  }),
}

const customMStyles = {
  menuList: (provided, state) => ({
    ...provided,

    borderBottom: "1px dotted pink",
    color: state.selectProps.menuColor,
    height: "200px",
  }),
}

const BookALesson = ({ location, userDetails }) => {
  let levelOptions = [
    { key: 1, value: "Beginner", label: "Beginner" },
    { key: 2, value: "Intermediate", label: "Intermediate" },
    { key: 3, value: "Advanced", label: "Advanced" },
  ]

  let [slots, setSlots] = useState([])
  let [level, setLevel] = useState(
    levelOptions[getWindowItem("level", 0) - 1]
      ? levelOptions[getWindowItem("level", 0) - 1]
      : ""
  )

  const [menuOpen, setMenuOpen] = useState(false)
  let [slotType, setSlotType] = useState(
    getWindowItem("slotType", "Once")
      ? getWindowItem("slotType", "Once")
      : "Once"
  )
  let [duration, setDuration] = useState(
    getWindowItem("duration", 30) ? parseInt(getWindowItem("duration", 30)) : 30
  )

  const [value, onChange] = useState(
    getWindowItem("date", new Date())
      ? getWindowItem("date", new Date())
      : new Date()
  )
  const [showCalendar, setShowCalendar] = useState(false)
  const [showStartDateCalendar, setShowStartDateCalendar] = useState(false)
  const [showEndDateCalendar, setShowEndDateCalendar] = useState(false)
  const [startDateValue, onChangeStartDate] = useState(
    getWindowItem("startdate", new Date())
      ? getWindowItem("startdate", new Date())
      : new Date()
  )
  const [endDateValue, onChangeEndDate] = useState(
    getWindowItem("enddate", new Date())
      ? getWindowItem("enddate", new Date())
      : new Date()
  )


  var [slotSelected, setSlotSelect] = useState("")
  const [s2, setSlotSelect2] = useState(1)
  const [tutors, setTutors] = useState([])
  const [tutorSlots, setTutorSlots] = useState({});
  const [selectedTimeSlots, setSelectedTimeSlots] = useState({})
  let [days, setDays] = useState(getWindowItem('days') ? JSON.parse(getWindowItem('days', { "Monday": false, "Tuesday": false, "Wednesday": false, "Thursday": false, "Friday": false, "Saturday": false, "Sunday": false })) : { "Monday": false, "Tuesday": false, "Wednesday": false, "Thursday": false, "Friday": false, "Saturday": false, "Sunday": false });
  const [sdays, setSDays] = useState({})
  const [modalIsOpen, setIsOpen] = useState(false)
  const [booking, usestes] = useState({})

  const [recurring, setRecurring] = useState(false)
  const [loading, setLoading] = useState(false)
  const [recurringSlotCount, setRecurringSlotCount] = useState(3);

  const [studentBookedTimeSlots, setStudentBookedTimeSlots] = useState({});


  var tutorList = {}
  const subjects = location.state ? location.state.subjects : []
  let selectedSubject = {}
  let [selectedSubjectValues, setSelectedSubjectValues] = useState(
    getWindowItem("selectedSubjectValues", subjects[0])
      ? getWindowItem("selectedSubjectValues", subjects[0])
      : ''
  )

  let selectSubjectKey = '1';
  for (var i in subjects) {
    if (subjects[i].name === selectedSubjectValues) {
      selectSubjectKey = i;
      break;
    }
  }

  const [subjectSelect, setSubjectSelect] = useState({ key: subjects[selectSubjectKey]?.id, id: subjects[selectSubjectKey]?.id, label: selectedSubjectValues, value: selectedSubjectValues });

  let [selectedTutor, setSelectedTutor] = useState(null)
  const [reviews, setReviews] = useState([])
  let [cart, setCart] = useState(
    getWindowItem("cart", false) ? JSON.parse(getWindowItem("cart")) : {}
  )

  let [wallet, setWallet] = useState({})
  let tutorDisplayed = false;

  days[sdays["day"]] = sdays["val"];

  let subjectOptions = []
  let subjectObj = {}
  let faculty = {}

  for (var i = 0; i < subjects.length; i++) {
    subjectOptions.push({
      key: subjects[i].id,
      label: subjects[i].name,
      value: subjects[i].id,
    })
    Object.assign(subjectObj, {
      [subjects[i].name]: {
        key: subjects[i].id,
        label: subjects[i].name,
        value: subjects[i].id,
      },
    })
    if (
      subjectSelect.value === subjects[i].id ||
      getWindowItem("selectedSubject", -1) == subjects[i].id
    ) {
      selectedSubject = subjects[i]
    }
  }

  if (!Array.isArray(subjects)) {
    subjects = []
  }

  if (subjectSelect.value) {
    faculty = subjects[subjectObj[subjectSelect.label].value]?.faculties[0]

    window.localStorage.setItem("selectedSubject", subjectSelect.value)
    if (subjectObj[subjectSelect.label]) {
      selectedSubjectValues = subjectObj[subjectSelect.label]
      window.localStorage.setItem("selectedSubjectValues", subjectSelect.label)
    }
  }

  useEffect(() => {
    window.localStorage.setItem("date", value)
  }, [value])

  useEffect(() => {
    window.localStorage.setItem("startdate", startDateValue)
  }, [startDateValue])

  useEffect(() => {
    window.localStorage.setItem("enddate", endDateValue)
  }, [endDateValue])

  useEffect(() => {
    window.localStorage.setItem("days", JSON.stringify(days))
  }, [sdays["day"]])

  useEffect(() => {
    window.localStorage.setItem("slotType", slotType)
  }, [slotType])

  useEffect(() => {
    window.localStorage.setItem("duration", duration)
  }, [duration])

  useEffect(() => {
    window.localStorage.setItem("level", level.key)
  }, [level])


  useEffect(() => {
    if (getWindowItem("selectedSubjectValues", false))
      setSelectedSubjectValues(
        subjectObj[getWindowItem("selectedSubjectValues", false)]
      )

    setSlotType(getWindowItem("slotType", "Once"))
    onChange(new Date(getWindowItem("date", new Date())))
    onChangeStartDate(new Date(getWindowItem("startdate", new Date())))
    onChangeEndDate(new Date(getWindowItem("enddate", new Date())))

    window.localStorage.removeItem("cart")


  }, [])

  useEffect(() => {
    document.querySelectorAll('.btn-check:checked').forEach((e, i) => {
      e.parentNode.scrollIntoView({ "block": "center", "inline": "center" })
    });
  })

  useEffect(async () => {
    async function getTutors() {
      let cond = {};

      switch (level.value) {
        case 'Beginner': cond = `subject=${selectedSubjectValues?.value}&level_in=Beginner&level_in=Intermediate&level_in=Advanced`; break;
        case 'Intermediate': cond = `subject=${selectedSubjectValues?.value}&level_in=Intermediate&level_in=Advanced`; break;
        case 'Advanced': cond = `subject=${selectedSubjectValues?.value}&level_in=Advanced`; break;
      }

      let res = await getMethod(`/faculty-subject-preferences?${cond}`, {});
      await setTutors(res.data)
    }

    if (selectedSubjectValues?.value && level?.value) getTutors()
  }, [selectedSubjectValues?.value, level?.value])

  useEffect(async () => {
    let date = new Date();
    date = date.toISOString().slice(0, 10);
    let time = new Date().toTimeString().slice(0, 8);
    let end_date = new Date(new Date().getTime() + (60000 * 60 * 24 * 60));
    end_date = end_date.toISOString().slice(0, 10);

    let d = {};
    let dd = [];
    let endDate = {};

    let faculty = {};

    if (recurring) {
      faculty = { faculty: selectedTutor.faculty.id }
    }

    for (var i in days) {
      if (days[i]) dd.push(i);
    }

    if (dd.length > 0 && recurring) {
      d = { day_in: dd };
    }

    endDate = { date_lte: end_date }


    setLoading(true);
    await clearBookingSlots();
    let slots = await getMethod("/slots", "", {
      date_gte: date,
      //date_lte: moment().add(45, 'days'),
      //start_time_gte: time,
      //isBooked: false
      ...endDate,
      ...d,
      ...faculty,
      _sort: `date:ASC,start_time:ASC`,
      _limit: 100000
      //date_lte: new Date,
    });

    setLoading(false);

    slotSelected = {};

    if (slots.data.length > 0) {

      //let groupedSlots = [];
      let groupedTimeSlots = [];
      let timeSlotTutors = {};
      let bookedTimeSlots = {};
      let groupedSlots = slots.data.reduce((r, a) => {
        console.log(new Date(a.date +'T'+ a.start_time+'Z'));
        a.server_date = a.date;
        a.date = moment(new Date(a.date+'T'+a.start_time+'Z')).format('yyyy-MM-DD');
        
       // console.log(a.date);
        
        if (groupedTimeSlots[a.date + '-' + a.start_time + '-' + a.duration +'-'+a.faculty.id] === undefined || (selectedTutor===null || a.faculty.id === selectedTutor.faculty.id)) {
          r[a.date] = [...(r[a.date] || []), a]
          groupedTimeSlots[a.date + '-' + a.start_time + '-' + a.duration+'-'+a.faculty.id] = '';
        }
        if (timeSlotTutors[a.faculty.id] === undefined)
          Object.assign(timeSlotTutors, { [a.faculty.id]: [] })

        Object.assign(timeSlotTutors[a.faculty.id], { [`${a.date}-${a.start_time}-${a.duration}`]: a });

        if(a.isBooked && a.cart_id.booked_by === userDetails.id){
          Object.assign(bookedTimeSlots,{[`${a.date}-${a.start_time}-${a.duration}`]:a.faculty.id});

          console.log(a);
          if(a.duration===60){
            Object.assign(bookedTimeSlots,{[`${a.date}-${a.start_time}-30`]:a.faculty.id});
            let addedTime = moment(`${a.date} ${a.start_time}`).add(30,'minutes').format("hh:mm:00.000");
            Object.assign(bookedTimeSlots,{[`${a.date}-${addedTime}-30`]:a.faculty.id});

          }
          
        }

        if(bookedTimeSlots[`${a.date}-${a.start_time}-${a.duration}`] && !a.isBooked){
          if(a.faculty.id === bookedTimeSlots[`${a.date}-${a.start_time}-${a.duration}`])
            delete bookedTimeSlots[`${a.date}-${a.start_time}-${a.duration}`]
        }

        return r
      }, {})

      
      setSlots(groupedSlots)
      setStudentBookedTimeSlots(bookedTimeSlots);
      setTutorSlots(timeSlotTutors);
      console.log(bookedTimeSlots);
    }

    async function getMyReview() {
      let sub2 = await getMethod("/reviews", "", {
        postedFor: selectedTutor.faculty?.id,
        _limit: 5,
      })

      setReviews(sub2.data)
    }
  }, [`${sdays["day"]}-${sdays["val"]}`, recurring, recurringSlotCount])

  function confirm() {

    let dayKeys = []
    let selectedDays = []
    // for (let i = 0; i < dayKeys.length; i++) {
    //   //if (days[dayKeys[i]]) selectedDays.push(dayKeys[i])
    // }
    navigate("/booking-checkout", {
      state: {
        faculty: selectedTutor,
        level: level.value,
        subject: subjectSelect,
        slotType: slotType,
        duration: duration,
        slot: {
          date: value,
          time: slots,
          duration: duration,
          startDate: startDateValue,
          endDate: endDateValue,
          days: selectedDays.join(","),
        },
      },
    })
  }

  const setBookingSlots = async (e, item, o) => {

    let s = slotSelected ? slotSelected : {};

    let selectedTimes = selectedTimeSlots ? selectedTimeSlots : {};

    if (!e?.target.checked && recurring) {
      // if (s[o.faculty.id] && Object.keys(s[o.faculty.id]).length < recurringSlotCount)
      //   s = {};
      // else
        if (s[o.faculty.id]) {
          await delete selectedTimes[`${o.server_date}-${o.start_time}`];
          await delete s[o.faculty.id][`${o.server_date}T${o.start_time}Z`]
          if (Object.keys(s[o.faculty.id]).length === 0) {
            await delete s[o.faculty.id]
          }
        }
    }
    else if (e?.target.checked) {
      let recurrCheck = s[o.faculty.id] === undefined;
      if (s[o.faculty.id] === undefined) Object.assign(s, { [o.faculty.id]: {} });
      Object.assign(s[o.faculty.id], { [`${o.server_date}T${o.start_time}Z`]: o });
      console.log(recurring,"::",s[o.faculty.id])
      if (recurring && (recurrCheck || Object.keys(s[o.faculty.id]).length === recurringSlotCount)) {
        for (var i in slots) {
          for (let l = 0; l < slots[i].length; l++) {
            if (o.isBooked) continue;
            if (new Date(`${slots[i][l].server_date} ${slots[i][l].start_time}`) < new Date() || new Date(`${slots[i][l].server_date} ${slots[i][l].start_time}`) < new Date(`${o.server_date} ${o.start_time}`)) continue;
            if (slots[i][l].duration === duration && slots[i][l].faculty.id === selectedTutor.faculty.id && o.start_time === slots[i][l].start_time) {
              Object.assign(s[o.faculty.id], { [`${slots[i][l].server_date}T${slots[i][l].start_time}Z`]: slots[i][l] });
            }

            if (Object.keys(s[o.faculty.id]).length === parseInt(recurringSlotCount)) break;
          }
          if (Object.keys(s[o.faculty.id]).length === parseInt(recurringSlotCount)) break;
        }
      }
      Object.assign(selectedTimes,{[`${o.server_date}-${o.start_time}`]:1})
    } else {
      await delete selectedTimes[`${o.server_date}-${o.start_time}`];
      if (s[o.faculty.id]) {
        await delete s[o.faculty.id][`${o.server_date}T${o.start_time}Z`]
        if (Object.keys(s[o.faculty.id]).length === 0) {
          await delete s[o.faculty.id]
        }
      }
    }

    if (selectedTutor) {
      for (var i in s) {
        if (!i == selectedTutor.faculty.id) {
          delete s[i];
        }
      }
    }

    if (recurring && e.target.checked !== undefined && Object.keys(s[o.faculty.id]).length >= recurringSlotCount) {
      let sdates = Object.keys(s[o.faculty.id]);
      let found = false;
      for (var k = 0; k < sdates.length; k++) {
        if (sdates[k].indexOf(`${o.server_date}T${o.start_time}Z`) === -1 && sdates[k].indexOf(o.server_date) >= 0) {
          await delete s[o.faculty.id][sdates[k]];
          found = true;
        }
      }
    }

    console.log("SSSS:::", s);

    setSlotSelect2(s2 + 1);
    setSlotSelect(s)
    setSelectedTimeSlots(selectedTimes)

    //if(e.target.checked)


    window.localStorage.setItem("slots", JSON.stringify(slotSelected))
    window.localStorage.setItem("date", item)
  }

  const clearBookingSlots = async () => {
    var s = {};

    window.localStorage.setItem("slots", "")
    window.localStorage.setItem("date", null)

    await setSlotSelect(s)
    await setSlotSelect2(1);
  }

  async function setRecurringSchedule() {
    if (!recurring && selectedTutor === null) {
      notify.show("Select a Tutor", "error");
      return;
    }
    setRecurring(!recurring);
  }

  const book = () => {

    if (selectedTutor == null) {
      setTimeout(
        () => notify.show("Please select a Tutor", "error"),
        10
      )
    } else if (selectedSlotData.join(',') == '') {
      setTimeout(
        () => notify.show("Please select a slot", "error"),
        10
      )
    } else {

      let chosenSlot = JSON.parse(window.localStorage.getItem("slots"));

      let chosenSlotKeys = Object.keys(chosenSlot);

      
      for(let i=0; i< chosenSlotKeys.length; i++){
        console.log(chosenSlotKeys[i]);
        let chosenSlotKeysDate = Object.keys(chosenSlot[chosenSlotKeys[i]]);
        for(let j=0; j< chosenSlotKeysDate.length; j++){
          
          if(chosenSlot[chosenSlotKeys[i]][chosenSlotKeysDate[j]].faculty.id===selectedTutor.faculty.id) continue;

          // console.log(chosenSlotKeysDate[j],':::',chosenSlotKeysDate[j].replace('Z',`-${duration}`),';;;',tutorSlots[selectedTutor.faculty.id]);
          let replacedSlot = tutorSlots[selectedTutor.faculty.id][chosenSlotKeysDate[j].replace('Z',`-${duration}`).replace('T','-')];
          chosenSlot[chosenSlotKeys[i]][chosenSlotKeysDate[j]] = replacedSlot;
        }
        
      }

      console.log(selectedSubject.id?selectedSubject:selectedSubjectValues)
      let confirmObj = {
        faculty: selectedTutor,
        level: level.value,
        subject: selectedSubject.id?selectedSubject:selectedSubjectValues,
        slotType: slotType,
        duration: duration,
        slot: {
          date: window.localStorage.getItem("date"),
          time: slots,
          duration: duration,
          startDate: startDateValue,
          endDate: endDateValue,
          slots: JSON.stringify(chosenSlot),
          dateNo: moment(window.localStorage.getItem("date")).format("DD"),
          month: moment(window.localStorage.getItem("date")).format("MMM"),
          day: moment(
            window.localStorage.getItem("date") +
            "" +
            window.localStorage.getItem("slots")
          ).format("LLLL"),
        },
      }

      usestes(confirmObj)
      setIsOpen(true)
    }
  }

  var recurringSlotsCount = {};

  const getTimeSlots = (slots, item) => {
    let timeSlots = []
    let displayedSlots = false;
    let displayedTimeSlots = {};

    slots.map( (key, j) => {
      //key.start_time =  await new Date(`${key.date}T${key.start_time}Z`).toTimeString().slice(0,8);

      let stime = new Date(`${key.date}T${key.start_time}Z`).toTimeString().slice(0,8);
      
      console.log("Start Time:::",key.faculty.id,'===',key.date,'===',key.start_time,':::',key.start_time,'==',new Date().toTimeString().slice(0,8));

      if (key.date === new Date().toISOString().slice(0, 10) && new Date(`${key.date}T${key.start_time}Z`) < new Date()) return;

      

      if (key.isBooked && key.cart_id.booked_by !== userDetails.id) return;
      if (recurring && recurringSlotsCount[key.start_time] < recurringSlotCount) return;

      let checked = {};

      if (recurring && duration === key.duration && key.faculty.id === selectedTutor.faculty.id && slotSelected[key.faculty.id]) {
        if (slotSelected[key.faculty.id][`${key.date}T${key.start_time}Z`])
          checked = { checked: true };
      } else if (!recurring && key.faculty.id === selectedTutor?.faculty?.id && slotSelected[key.faculty.id]) {
        console.log("Recurring::",recurring,"::",slotSelected,"::",recurringSlotsCount);
        //if(slotSelected[key.faculty.id][`${key.date}T${key.start_time}Z`])
        //checked=true;
      }

      

      if((key.faculty.id === selectedTutor?.faculty?.id || selectedTutor === null ) &&  displayedTimeSlots[`${moment(item + " " + stime).format("hh:mm a")}-${key.duration}`]!==undefined) return;

      if(key.faculty.id === selectedTutor?.faculty?.id || selectedTutor === null ) Object.assign(displayedTimeSlots, {[`${moment(item + " " + stime).format("hh:mm a")}-${key.duration}`]:true});
      
      console.log(selectedTutor,':::',key);
      console.log(`${key.date}-${key.start_time}-${key.duration}`)
      if(studentBookedTimeSlots[`${key.date}-${key.start_time}-${key.duration}`] && key?.cart_id?.booked_by !== userDetails.id) return;

      (key.faculty.id === selectedTutor?.faculty?.id || selectedTutor === null || key?.cart_id?.booked_by === userDetails.id) && duration === key.duration && timeSlots.push(
        <div

        >
          <input
            type="checkbox"
            class={key.isBooked ? "btn-check bg-green" : 'btn-check'}
            id={`btncheck${item}${j}-${key.duration}`}
            autocomplete="off"
            disabled={key.isBooked ? true : false}
            {...checked}
            onChange={(e) => {
              if (!key?.isBooked) {
                // if (slotSelected[selectedTutor?.faculty?.id] && recurring) {
                //   if (Object.keys(slotSelected[selectedTutor?.faculty?.id]).length < recurringSlotCount)
                //     setBookingSlots(e, item, key);
                // } else if (!recurring) {
                //   setBookingSlots(e, item, key);
                // }else 
                console.log(e, item, key);
                  setBookingSlots(e, item, key);
              }
              else
                return false;
            }}
          />
          <label class={key.isBooked ? "btn btn-success btn-md big mb-0 square-btn" : "btn btn-outline-primary big mb-0 square-btn"} for={`btncheck${item}${j}-${key.duration}`}

          >
            {" "}
            {`${moment(item + " " + stime).format("hh:mm a")}`}
          </label>
        </div>
      )
    })

    if (timeSlots.length === 0) {
      timeSlots.push(<div className="w-100"><button disabled class="btn btn-outline-primary w-100 mb-0">No slots available</button></div>);
    }

    return timeSlots
  }

  const getSlots = () => {
    recurringSlotsCount = {};

    for (var i in slots) {
      for (let l = 0; l < slots[i].length; l++) {
        if (slots[i][l].duration === duration && selectedTutor?.faculty.id === slots[i][l].faculty.id) {
          Object.assign(recurringSlotsCount, { [slots[i][l].start_time]: recurringSlotsCount[slots[i][l].start_time] ? recurringSlotsCount[slots[i][l].start_time] + 1 : 1 })
        }
      }
    }

    return Object.keys(slots).map((item, i) => {
      return (
        <div className="sticky-scroller">
          <table className="table table-borderless mb-0">
            <tbody>
              <tr>
                <th className="sticky-col">
                  <div>
                    <div className="date">{`${moment(item).format("DD MMM")}`}</div>
                    <div className="day">{`${moment(item).format("ddd")}`}</div>
                  </div>
                </th>
                <td>
                  <div className="d-flex slot-time">
                    {getTimeSlots(slots[item], item)}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      )
    })
  }

  function closeModal() {
    setIsOpen(false)
  }

  async function recharge(cart, balancePaymentHrs) {
    navigate("/wallet-payment", {
      state: { cart: cart, balancePaymentHrs: balancePaymentHrs },
    })
  }

  async function checkOut() {
    console.log(booking);
    //let fac = booking.slot.slots.
    let newbooking = {
      subject: booking.subject.id?booking.subject.id:booking.subject.key,
      faculty: booking.faculty.id,
      // start_time: location.state.slot.date + ' ' + location.state.slot.start_time,
      // end_time: location.state.slot.date + ' ' + location.state.slot.end_time,
      date: booking.slot.date,
      time: booking.slot.slots.trim(),
      booked_by: userDetails.id,
      payment: "0",
      slot_type: "",
      level: booking.level,
      duration: booking.duration,
      //start_date: new Date(location.state.slot.startDate.valueOf()+ (1000 * 60 * 60 * ((-1*new Date().getTimezoneOffset())/60))).toISOString().slice(0,10),
      end_date: booking.slot.date,
      "days": "",
      "cart": {}
    }

    console.log("newbooking", newbooking)

    let resp = await postMethod('/booking', newbooking);
    if (resp.data.cart.balancePaymentHrs) {
      window.localStorage.setItem('cart', JSON.stringify(resp.data.cart));
      setCart(resp.data.cart);

    }
    else
      navigate('/dashboard?booking=success&v='+window.localStorage.getItem('appVersion'));
  }

  let selectedSlotData = [];
  for (var k in slotSelected) {
    for (var l in slotSelected[k]) {
      let time = `${slotSelected[k][l].server_date}T${slotSelected[k][l].start_time}Z`;
      selectedSlotData.push(`${moment(time).format("ddd DD-MMM hh:mm a")}`);
    }

  }
  let checked30 = duration === 30 ? 'checked="checked' : '';
  let checked60 = duration === 60 ? 'checked="checked' : '';

  return (
    <Layout title={`Book-a-Lesson`}>
      <Seo title="Home" />
      <div className="container">
        <div className="row gx-3">
          <div className="col-6">
            <div className="select-wrapper">
              <Select
                placeholder="Subject"
                isSearchable={false}
                fluid
                options={subjectOptions}
                onChange={setSubjectSelect}
                styles={customStyles}
                value={selectedSubjectValues}
              />
            </div>
          </div>
          <div className="col-6">
            <div className="select-wrapper">
              <Select
                placeholder="Level"
                fluid
                isSearchable={false}
                options={levelOptions}
                styles={customStyles}
                onChange={setLevel}
                value={level}
              />
            </div>
          </div>
        </div>


        {subjectSelect && level && s2 && <>
          <h6><strong>Select Tutor</strong></h6>
          <div className="tutor-scroll">
            <div className="row flex-nowrap gx-3">
              {s2 && tutors.map((tutor, i) => {
                console.log(tutorSlots[`${tutor.faculty.id}`]);
                let tutorTimeSlot = false;
                let selectedTimeslotKeys = Object.keys(selectedTimeSlots);
                console.log(selectedTimeSlots);
                for(let i=0; i<selectedTimeslotKeys.length; i++){
                    if(tutorSlots[`${tutor.faculty.id}`]===undefined){ break;}
                    console.log(tutor.faculty.id,':::',selectedTimeslotKeys[i],':::',tutorSlots[`${tutor.faculty.id}`][`${selectedTimeslotKeys[i]}-${duration}`]);
                    if(tutorSlots[`${tutor.faculty.id}`][`${selectedTimeslotKeys[i]}-${duration}`]){
                      tutorTimeSlot = true;
                      //break;
                    }
                }
                console.log(tutorTimeSlot);
                if ((Object.keys(slotSelected).length > 0 && slotSelected[`${tutor.faculty.id}`] === undefined && (!tutorTimeSlot))  || ((new Date().getFullYear() - new Date(userDetails.date_of_birth).getFullYear()) >= 12 && userDetails.gender !== tutor.faculty.gender)) return <></>;
                tutorDisplayed = true;
                console.log(tutor.faculty);
                return (<div className="col-9"><div key={i} className={selectedTutor?.faculty?.id === tutor.faculty.id ? 'tutor-container selected text-white' : 'tutor-container'} onClick={() => setSelectedTutor(selectedTutor?.faculty?.id === tutor.faculty.id ? null : tutor)}><Tutor
                  teacher={tutor.faculty}
                ></Tutor></div></div>)
              }

              )}
            </div>
          </div>
          {!tutorDisplayed && <>
            <div class="alert alert-danger border-0" role="alert" style={{marginTop:"8px"}}>No tutor available for the selected subject and level !</div>
          </>}
          {subjectSelect && level && <div>

            <h6><strong>Select Duration</strong></h6>
            <div class="sticky-table">
              <div
                onClick={e => {
                  setDuration(30); clearBookingSlots();
                }}
                style={{ float: "left", marginRight: '10px' }}
              >
                <input
                  type="checkbox"
                  className="btn-check"
                  id={`btncheck-Duration-30`}
                  autocomplete="off"
                  checked={duration === 30 ? true : false}
                  onChange={() => { }}
                />
                <label class="btn btn-sm btn-outline-primary big mb-0" for={`btncheck-Duration-30`}>
                  30 Minutes
                </label>
              </div>
              <div
                onClick={e => {
                  setDuration(60); clearBookingSlots();
                }}
              >
                <input
                  type="checkbox"
                  className="btn-check"
                  id={`btncheck-Duration-60`}
                  autocomplete="off"
                  checked={duration === 60 ? true : false}
                  onChange={() => { }}
                />
                <label class="btn btn-sm btn-outline-primary big mb-0" for={`btncheck-Duration-60`}>
                  60 Minutes
                </label>
              </div>
            </div>
          </div>
          }
          {s2 && subjectSelect && level && (recurring || !recurring) && recurringSlotCount && <div class="date-time-section pb-0">

            <h6><strong>Select Date &amp; Time</strong></h6>
            <div className="sticky-table">
              <input
                type="checkbox"
                className=""
                id={`btncheck-recurring`}
                checked={recurring}
                onChange={() => { clearBookingSlots(); setRecurringSchedule() }}
              />
              <span>    Show Recurring slots for   </span>
              <select name="recurring-days" value={recurringSlotCount} onChange={(event) => { clearBookingSlots(); setRecurringSlotCount(event.target.value) }}>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
                <option value="7">7</option>
                <option value="8">8</option>
                <option value="9">9</option>
                <option value="10">10</option>
              </select>
              <span>  lessons</span>
              {recurring && (<div className="row m-sm" >
                <label className="sm">Select Days</label>
                <div>
                  <div className={days["Monday"] ? "btn btn-day btn-primary" : "btn btn-day  btn-outline-secondary"} onClick={() => setSDays({ "day": "Monday", "val": !days["Monday"] })}>M</div>
                  <div className={days["Tuesday"] ? "btn btn-day btn-primary" : "btn btn-day  btn-outline-secondary"} onClick={() => setSDays({ "day": "Tuesday", "val": !days["Tuesday"] })}>T</div>
                  <div className={days["Wednesday"] ? "btn btn-day btn-primary" : "btn btn-day  btn-outline-secondary"} onClick={() => setSDays({ "day": "Wednesday", "val": !days["Wednesday"] })}>W</div>
                  <div className={days["Thursday"] ? "btn btn-day btn-primary" : "btn btn-day  btn-outline-secondary"} onClick={() => setSDays({ "day": "Thursday", "val": !days["Thursday"] })}>T</div>
                  <div className={days["Friday"] ? "btn btn-day btn-primary" : "btn btn-day  btn-outline-secondary"} onClick={() => setSDays({ "day": "Friday", "val": !days["Friday"] })}>F</div>
                  <div className={days["Saturday"] ? "btn btn-day btn-primary" : "btn btn-day  btn-outline-secondary"} onClick={() => setSDays({ "day": "Saturday", "val": !days["Saturday"] })}>S</div>
                  <div className={days["Sunday"] ? "btn btn-day btn-primary" : "btn btn-day  btn-outline-secondary"} onClick={() => setSDays({ "day": "Sunday", "val": !days["Sunday"] })}>S</div>
                </div>
              </div>)}

              {loading && <div class="loader" ></div>}
              {!loading && getSlots()}
            </div>
          </div>}
        </>}


        {!subjectSelect.value && <div className="fixed-btn-space">
          <div className="fixed-btn-wrapper">
            <div
              className="btn btn-primary w-100 mb-0"
            >
              Select Subject
            </div>
          </div>
        </div>}

        {subjectSelect.value && !level && <div className="fixed-btn-space">
          <div className="fixed-btn-wrapper">
            <div
              className="btn btn-primary w-100 mb-0"
            >
              Select Level
            </div>
          </div>
        </div>}

        {subjectSelect.value && level && (!selectedTutor && Object.keys(slotSelected).length == 0) && <div className="fixed-btn-space">
          <div className="fixed-btn-wrapper">
            <div
              className="btn btn-primary w-100 mb-0"
            >
              Select Tutor and Slot
            </div>
          </div>
        </div>}

        {subjectSelect.value && level && (selectedTutor && Object.keys(slotSelected).length == 0) && <div className="fixed-btn-space">
          <div className="fixed-btn-wrapper">
            <div
              className="btn btn-primary w-100 mb-0"
            >
              Select Slot(s) - [scroll for more]
            </div>
          </div>
        </div>}

        {subjectSelect.value && level && (!selectedTutor && Object.keys(slotSelected).length > 0) && <div className="fixed-btn-space">
          <div className="fixed-btn-wrapper">
            <div
              className="btn btn-primary w-100 mb-0"
            >
              Select Tutor
            </div>
          </div>
        </div>}

        {subjectSelect && level && selectedTutor && Object.keys(slotSelected).length > 0 && <div className="fixed-btn-space">
          <div className="fixed-btn-wrapper">
            <div
              className="btn btn-primary w-100 mb-0"
              onClick={() => book()}
            >
              Book Now
            </div>
          </div>
        </div>}


        <div style={{ position: 'fixed', bottom: 0, width: '100%' }}>
          <Modal show={modalIsOpen} contentLabel="Example Modal" >
            <Modal.Body >
              <div className="row align-items-center">
                <div className="col"><h6 className="mb-0"><strong>Class Details</strong></h6></div>
                <div className="col-auto ms-auto"><button class="btn btn-sm btn-link mb-0" onClick={closeModal}>Back</button></div>
              </div>
              <div className="booking-details">
                <div className="tutor-name">
                  <div className="row align-items-center">
                    <div className="col">
                      <b>Subject : </b>{`${selectedSubjectValues?.label}`}
                    </div>
                  </div>
                </div>
                <div className="tutor-name">
                  <div className="row align-items-center">
                    <div className="col">
                      <b>Duration :</b> {`${duration} minutes`}
                    </div>
                  </div>
                </div>
                <div className="tutor-name">
                  <div className="row align-items-center">
                    <div className="col">
                      <b>Level :</b> {`${level.label}`}
                    </div>
                  </div>
                </div>
                <div className="tutor-name">
                  <div className="row align-items-center">
                    <div className="col">
                      <b>Student :</b> {`${userDetails?.firstname} ${userDetails?.lastname}`}
                    </div>
                  </div>
                </div>
                <div className="tutor-name">
                  <div className="row align-items-center">
                    <div className="col">
                    <b>Tutor :</b> <i className="icon-Profile text-primary"></i>{`${selectedTutor?.faculty?.firstname} ${selectedTutor?.faculty?.lastname}`}
                    </div>
                    {/* <div className="col-auto ms-auto">
                      <div className="rating">
                        <i className="icon icon-Star_Fill" />
                        <span>4.8</span>
                      </div>
                    </div> */}
                  </div>
                </div>
                {selectedSlotData.map((d, i) => {
                  return (<div key={i} className="booking-date"><b>Slot :</b> <i className="icon-Time text-primary"></i>{d}</div>)
                })}
              </div>
              {wallet?.balance_hrs && wallet?.balance_hrs > 0 && (
                <h6 class="text-center mb-3">
                  <strong>
                    Wallet Balance: {wallet?.balance_hrs ? wallet.balance_hrs : 0}{" "}
                    hrs
                  </strong>
                </h6>)}
              {/* 
              {cart.balancePaymentHrs > 0 && (
                <div className="btn  w-100">
                  Payment: {cart.balancePaymentHrs}{" "}
                  {cart.balancePaymentHrs > 1 ? "hrs" : "hr"}
                </div>
              )} */}
              {cart.balancePaymentHrs > 0 && (
                <div
                  className="btn btn-primary w-100 mb-0"
                  onClick={() => recharge(cart, cart.balancePaymentHrs)}
                >
                  Pay for {cart.balancePaymentHrs}{" "}
                  {cart.balancePaymentHrs > 1 ? "hrs" : "hr"} and Confirm
                </div>
              )}
              {!cart.balancePaymentHrs && (
                <div
                  className="btn btn-primary w-100 mb-0"
                  onClick={() => checkOut()}
                >
                  Checkout
                </div>
              )}
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </Layout>
  )
}

export default connect(
  state => ({ userDetails: state.loginReducer.authenticate.user }),
  null
)(BookALesson)
